#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.sider {
    height: calc(100vh - 96px);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
}

.sider::-webkit-scrollbar {
    display: none;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-content {
    background: #fff;
    padding: 0;
    margin: 0;
    display: flex;
    height: calc(100vh - 4em);
}

.leftSection {
    display: flex;
}

.rightSection {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-family: Noto Sans, PT Root UI, sans-serif;
    line-height: 16px;

    &_welcome {
        margin: 0 1.5rem 0 0;
    }

    &_login {
        font-weight: bold;
    }
}

.logoutBlock {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_text {
        margin: 0 0 0 0.6rem;
        color: #c2edff;
        font-family: Roboto, PT Root UI, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    &_icon {
        color: #c2edff;
    }
}

.header {
    background-color: #14213d;
    height: 48px;
    padding-left: 12px !important;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 1rem;
    margin: 0.5rem 0;
    width: 182px;
    &_image {
        width: 32px;
    }
    &_titleBlock {
        display: flex;
        flex-direction: column;
    }
    &_title {
        margin: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
    }

    &_subtitle {
        @extend .logo_title;
        font-weight: normal;
    }
}

.layoutContent {
    padding: 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.headerContent {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.menuIcon {
    width: 1rem !important;
    svg {
        stroke: none !important;
    }
    margin-top: -3px;
}

.outerMenu {
    height: 100%;
    border-right: 0;
    color: #486175;
    font-weight: bold;
}

.menu-item {
    color: #486175;
    font-weight: normal;
}

.menu-item.ant-menu-item-selected {
    color: #00a7ee;
}

.ant-menu-item-selected a {
    color: #00a7ee;
}

.ant-menu-submenu-selected {
    color: #1fbcff;
}

.footerBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.versionText {
    margin: 0;
    font-family: 'Roboto', PT Root UI, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.versionBlock {
    opacity: 0;
    animation: show 0.2s 0.1s forwards;
    text-align: left;
    padding-right: 16px;
    padding-left: 20px;
    cursor: text;
}

.auth {
    &_versionBlock {
        position: absolute;
        bottom: 16px;
        left: 16px;
    }

    &_versionText {
        @extend .versionText;
    }
}

@keyframes show {
    to {
        opacity: 1;
    }
}

.ant-tooltip {
    position: fixed;
}

.pickers-wrapper {
    width: 100%;
    .ant-picker {
        width: 100%;
    }
}

@media screen and (max-width: 720px) {
    .ant-picker-panel-container {
        transform: scale(0.9);
    }
    .ant-layout-header {
        width: 100vw;
        overflow: auto;
    }
}

@media screen and (max-width: 480px) {
    .ant-picker-panel-container {
        transform: scale(0.7)translateX(-20%);
    }
    .ant-layout-sider:not(.ant-layout-sider-collapsed) {
       position: absolute;
       z-index: 2;
    }
}